import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../utils/seo"

function Blog({ data }) {
  const { blogPosts } = data

  return (
    <Layout title="El blog d'Artistes Pedagogs">
      <SEO title="El blog d'Artistes Pedagogs" />
      <div className="container" style={{ maxWidth: 1000, margin: '30px auto'}}>
        {blogPosts.edges.map(({ node: post }) => (
          <div key={post.id} style={{ padding: 10, margin: 5, border: '1px solid #2a88bf' }}>
            <Link to={`/blog/${post.slug}`}>
              <h3>{post.title}</h3>
            </Link>
            <br/>
            <span>{post.spoiler}</span>
          </div>
        ))}
      </div>
    </Layout>
  )
}
export default Blog

export const pageQuery = graphql`
  query BlogPosts {
    blogPosts: allContentfulBlogPost(limit: 10) {
      edges {
        node {
          id
          title
          spoiler
          slug
        }
      }
    }
  }
`
